import css from "./Input.module.css";
import React, {useState, useRef} from "react";
import {getSafeDeep} from "@/util/state";
import cx from "classnames";

export const DescriptionField = ({theme, error, errorOverflow, affirmation, info, inline}) => {

    if (inline) return <></>;
    if (!error && !affirmation && !info) return <></>;

    let message = error;
    let classString = "error";

    if (info) {
        message = info;
        classString = "info";
    }

    if (affirmation) {
        message = affirmation;
        classString = "affirmation";
    }


    return <div className={cx(css[`${classString}Below`], {[css.errorOverflow]: errorOverflow})}>
        {message}
    </div>
};


function Input(props) {
    const [inputFocused, setInputFocus] = useState(false);
    const inputRef = useRef(null);

    const {error, errorOverflow, onInvalid, hint, transparent, suffixClassName, inputClassName, onBlurWithEvent, actionFloating, actionLabel, onFocus, onBlur, actionHandler, placeholder, value, name, disabled, maxLength, type, prefix, onChange, required, inline, theme, affirmation, info, style, suffix, min, max, step} = props;

    const isPlaceholderRaised = () => {
        return inputFocused || value || getSafeDeep(inputRef, "current.value", false) || hint;
    };

    const inputWrapperTheme = theme ? `${theme}InputWrapper` : "";
    const inputTheme = theme ? `${theme}Input` : "";
    const placeholderTheme = theme ? `${theme}Placeholder` : "";

    return <div style={style} className={cx(css.wrapper, props.wrapperClassName)}>
        {actionLabel && <button type={"button"} onClick={actionHandler}
                                className={cx(css.action, {[css.actionFloating]: actionFloating})}>{actionLabel}</button>}
        <div className={cx(props.className, css.container, {[css.focused]: inputFocused}, {[css.disabled]: disabled}, {[css.transparent]: transparent},
            {[css.affirmed]: affirmation}, css[inputWrapperTheme])}>
            {error ? <div
                    className={cx(css.error, {[css.red]: inline}, css.hover, css[placeholderTheme])}>{inline ? error : placeholder}</div>
                : <div data-type="placeholder"
                       className={cx(css.placeholder,
                           css[placeholderTheme],
                           {[css.hover]: isPlaceholderRaised()},
                           {[css.moveRight]: prefix},
                           {[css.transparent]: transparent}
                       )}>{placeholder}</div>
            }

            {prefix && <div className={css.prefix}>{prefix}</div>}

            <input
                className={cx(css.input, {
                    [css.moveLeft]: prefix,
                    [css.prefixedWidth]: prefix,
                    [css.transparent]: transparent
                }, css[inputTheme], inputClassName)}
                onBlur={(e) => {
                    setInputFocus(false);
                    if (onBlur) onBlur();
                    if (onBlurWithEvent) {
                        onBlurWithEvent(e);
                    }
                }}
                onFocus={() => {
                    setInputFocus(true);
                    if (onFocus) onFocus();
                }}
                onKeyPress={props.onKeyPress}
                onKeyPressCapture={props.onKeyPressCapture}
                ref={inputRef}
                value={value}
                min={min}
                max={max}
                step={step}
                onInvalid={onInvalid}
                placeholder={hint}
                maxLength={maxLength}
                name={name}
                onChange={onChange}
                type={type}
                disabled={disabled}
            />

            {suffix && <div className={cx(css.suffix, suffixClassName)}>{suffix}</div>}

        </div>
        <DescriptionField errorOverflow={errorOverflow} error={error} affirmation={affirmation} info={info}
                          theme={theme} inline={inline}/>
    </div>
}

export default Input
